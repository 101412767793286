// src/components/Header.js
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Header_packages = ({ title }) => {

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // List of paths where the back button should not appear
  const noBackButtonPaths = [
    '/health',
    '/income',
    '/accidental',
    '/car',
    '/bike',
    '/crop',
    '/womenHealth',
    '/liveStock',
    '/mobile',
  ];

  return (
    <div className="container-fluid" style={{ backgroundColor: "#1bb367" }}>
      <div className="container">
        <div className="row py-2 align-tems-center pb-0" style={{marginBottom: "-1px"}}>
          {/* {
          !noBackButtonPaths.includes(location.pathname) && (
            <div className="col-2 d-flex justify-content-start align-items-center">
              <button className="btn btn-link" onClick={handleBackClick}>
                <FaArrowLeft size={15} />
              </button>
            </div>
          )
        } */}
          <div className="col-2">
            <button className="btn btn-link text-white ps-0" onClick={handleBackClick}>
              <FaChevronLeft size={15} />
            </button>
          </div>

          <div className="col-8 text-center my-auto">
            <h6 className="mb-0 custom-heading bg-white rounded-pill fw-normal py-1" dangerouslySetInnerHTML={{ __html: title }}></h6>
          </div>
          <div className="col-2 my-auto text-end">
            <Link onClick={handleShow} className="text-white text-decoration-none">Help</Link>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Help</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <div className="card p-2 text-white text-center" style={{ backgroundColor: "#1bb367" }}>
            Call Now for Help
            <a href="tel:03443333737" className="d-block fs-4 fw-bold mt-0 text-decoration-none text-white mb-2">0344 3333737</a>
            Or email us at
            <a href="mailto:support@webdoc.com" className="d-block fs-4 fw-bold mt-0 text-decoration-none text-white">support@webdoc.com</a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header_packages;