import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import PackageScreen from "../components/pacakge-screen";
import getInsuranceData from "../modals/getInsuranceData";
import Header_packages from "../components/Header_packages";
import ActivatedPackageComponent from "../components/activatedPackage";
import Header from "../components/Header";

import { useLoading } from "../contexts/LoadingContext";

const LiveStock = () => {

    const [userData, setUserData] = useState(null);
    const [details, setDetails] = useState(null);
    const { isLoading, setIsLoading } = useLoading();
    const type = "livestock";
    const service = "insurance";
    const id = "1025";

    const [customerNumber, setCustomerNumber] = useState(null);


    // const user = {
    //   openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
    //   unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
    //   userMsisdn: "0345000014",
    //   userName: "Khumash Hussain",
    //   applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
    //   // applicationUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
    //   userEmail: "03145362496@webdoc.com.pk",
    //   // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
    //   package: "",
    //   message: "User registered and authenticated successfully  .",
    // };

    // sessionStorage.setItem("user", JSON.stringify(user));
    // const customerNumber ="0345000014";


    // Function to handle user authentication code result
    const getUserAuthCodeResult = (jsonResult) => {
        const result = JSON.parse(jsonResult);
        try {
            if (result.status === "SUCCESS") {
                const form = new FormData();
                form.append("value", result.authCode);

                axios
                    .post(
                        "https://easypaiseminiapp.webddocsystems.com/public/api/v1/auth",
                        form,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.openId) {
                            sessionStorage.setItem("user", JSON.stringify(response.data));
                            setUserData(response.data); // Set the user data
                            setCustomerNumber(response.data.userMsisdn);
                            //customerNumber = response.data.userMsisdn;
                        } else {
                            Swal.fire({
                                icon: "info",
                                allowOutsideClick: false,
                                title: "Something went wrong. Please try again later!",
                                confirmButtonText: "Retry",
                            }).then(() => {
                                authenticateUser();
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setIsLoading(false); // Stop loading on error
                    });
            } else {
                Swal.fire({
                    allowOutsideClick: false,
                    title: "Please Share the information for Services",
                    confirmButtonText: "Retry",
                }).then((result) => {
                    if (result.isConfirmed) {
                        authenticateUser();
                    } else {
                        setIsLoading(false);
                    }
                });
            }
        } catch (error) {
            console.error("Error processing auth code:", error);
            setIsLoading(false);
        }
    };

    // Function to authenticate the user
    const authenticateUser = () => {
        setIsLoading(true);
        const scopes = [
            "USER_MSISDN",
            "USER_NAME",
            "OPEN_ID",
            "UNION_ID",
            "USER_BIRTHDAY",
        ];
        // Trigger authentication code (implementation not provided in your code)
        window.mp.getUserAuthCode(scopes);
        window.getUserAuthCodeResult = getUserAuthCodeResult;
    };

    // Fetch subscription status
    const fetchSubscriptionStatus = async (customerNumber, service, id) => {
        setIsLoading(true); // Set loading to true when fetching

        try {
            const response = await fetch(
                `https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=${id}&customerNumber=${customerNumber}&service=${service}`,
                {
                    method: "GET",
                    headers: {
                        Authorization:
                            "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
                    },
                }
            );
            const data = await response.json();
            setDetails(data); // Save subscription details in state
        } catch (error) {
            console.error("Error fetching subscription status:", error);
        } finally {
            setIsLoading(false); // Set loading to false once the API call is complete
        }
    };

    // Use effect to start the authentication process
    useEffect(() => {
        setIsLoading(true);
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        //console.log(sessionUser);
        if (sessionUser) {
            setIsLoading(false);
            setUserData(sessionUser);
            setCustomerNumber(sessionUser.userMsisdn);
            //customerNumber = sessionUser.userMsisdn;
        } else {
            authenticateUser();
        }
    }, []); // The effect runs only once on mount

    // Use effect to fetch subscription status after user is authenticated
    useEffect(() => {
        if (userData && customerNumber && service && id) {
            fetchSubscriptionStatus(customerNumber, service, id); // Fetch subscription status after user is authenticated
        }

        //console.log(userData);
        console.log(customerNumber);
        //console.log(service);
        //console.log(id);
    }, [userData]); // Trigger fetching when userData is set

    const { items, terms } = getInsuranceData(type);

    // Render a loading state or the actual content
    // if (isLoading) {
    //   return (
    //     <div>Loading...</div>
    //   );
    // }

    if (isLoading) {
        return;
    }
    // If user is authenticated and responseCode is "0000"


    if (userData && details?.responseCode === "0000" && details) {

        details.type = type;
        return (
            <div>
                <Header_packages
                    title={`${type.charAt(0).toUpperCase() + type.slice(1)} Package`}
                />
                <Header userSession={JSON.parse(sessionStorage.getItem("user"))} />
                <ActivatedPackageComponent items={details} />
            </div>
        );
    }
    else {
        return (
            <div>
                <Header_packages title="Live Stock" />
                <Header userSession={JSON.parse(sessionStorage.getItem("user"))} packageData='notActive' />
                <PackageScreen items={Array.isArray(items) ? items : []} terms={terms} />
            </div>
        );

    }

    // If no valid userData or other conditions

};

export default LiveStock;
