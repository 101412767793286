// src/components/ActivatedPackageComponent.js
import React, { useState } from "react";
import Swal from "sweetalert2";
import "../../src/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import { BsDatabaseCheck } from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PocketSizeActivatedPackageComponent = ({ items }) => {
  const { isLoading, setIsLoading } = useLoading();
  // console.log(items)
  const [transactions, setTransactions] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const number = userData?.userMsisdn || null;
  const navigate = useNavigate();
  const location = useLocation();

  const redirectFromPaymentSuccess = sessionStorage.getItem(
    "redirectFromPaymentSuccess"
  );
  if (redirectFromPaymentSuccess === "true") {
    Swal.fire({
      icon: "info",
      title: "Confirmation",
      text: "Your package has been activated successfully.",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then(() => {
      // Clear the flag from sessionStorage
      sessionStorage.removeItem("redirectFromPaymentSuccess");
    });
  }

  const handleUnsubscribe = (pidsub) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to unsubscribe from this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, unsubscribe",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const OpenId = user.openId;
        const pid = pidsub;
        const service = "Insurance";
        const number = user.userMsisdn || null;

        try {
          const response = await fetch(
            `https://insuranceservice.webddocsystems.com/api/MiniApp/UnsubServices`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                  "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
              },
              body: JSON.stringify({
                OpenId,
                service,
                pid,
                number,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          console.log(data.responseCode);
          if (data.responseCode === "0000") {
            // Remove the package from the active subscriptions
            const updatedTransactions = transactions.filter(
              (t) => t.activePackage.productId !== pid
            );

            // Update the state to reflect the changes
            setTransactions(updatedTransactions);

            Swal.fire({
              title: "Unsubscribed!",
              text: "You have successfully unsubscribed from the service.",
              icon: "success",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(true);
              window.location.reload();
              //navigate("/income")
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            });
          }
        } catch (error) {
          console.error("Error unsubscribing:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error unsubscribing from the service. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
        }
      }
    });
  };
  // console.log(items);

  return (
    <div className="container-fluid">
      <div className="container">
        {Array.isArray(items) && items.length > 0 ? (
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            useKeyboardArrows
            showIndicators={false}
            autoPlay={false}
          >
            {items.map((item, index) => (
              <div key={index} className="row mt-2 position-relative mx-1">
                <div className="card selected_package border-2 lightt_greenn">
                  <div className="card-header p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-end">
                        <div className="flex-shrink-0">
                          <BsDatabaseCheck fontSize={35} />
                        </div>
                        <div className="flex-grow-1 ms-2">
                          <h5 className="fw-bold mb-0">
                            {item.pkgPrice}/
                            <span
                              className="fw-light text-secondary"
                              style={{ fontSize: "12px" }}
                            >
                              {item.pName}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="mb-0 text-end">
                        {/* <small className="d-block">
                          Package: <span>{item.pName}</span>
                        </small> */}
                        <span
                          className="badge mb-1 mt-1 fw-light fs-6 rounded-pill"
                          style={{ backgroundColor: "#1bb367" }}
                        >
                          Subscribed
                        </span>
                        <span
                          className="badge mb-1 mt-1 fw-light fs-6 rounded-pill"
                          style={{ backgroundColor: "#f44336" }}
                          onClick={() => handleUnsubscribe(item.pid)}
                        >
                          Unsub
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-start">
                    <p className="mb-1">
                      Active Date:{" "}
                      <span
                        className="fw-light text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.activateDate}
                      </span>
                    </p>
                    <p className="mb-1">
                      Expire Date:{" "}
                      <span
                        className="fw-light text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.expiryDate}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default PocketSizeActivatedPackageComponent;
