import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import { useLoading } from "../contexts/LoadingContext";
import Swal from "sweetalert2";
import "../../src/style.css";

const MyActivePackages = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoading, setIsLoading } = useLoading();

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const openId = userData?.openId || null;
  const number = userData?.userMsisdn || null;

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://insuranceservice.webddocsystems.com/api/MiniApp/GetMyActivePackages?OpenId=${openId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTransactions(data.packages || []);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [openId, setIsLoading]);

  const handleUnsubscribe = (transaction) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to unsubscribe from this service?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, unsubscribe",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const OpenId = user.openId;
        const pid = transaction.activePackage.productId;
        const service = transaction.activePackage.serivcename;
        console.log(service);
        try {
          const response = await fetch(
            "https://insuranceservice.webddocsystems.com/api/MiniApp/UnsubServices",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization:
                  "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
              },
              body: JSON.stringify({
                OpenId,
                service,
                pid,
                number,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          if (data.responseCode === "0000") {
            const updatedTransactions = transactions.map((t) =>
              t.activePackage.productId === pid
                ? {
                    ...t,
                    activePackage: { ...t.activePackage, status: "UnSub" },
                  }
                : t
            );
            setTransactions(updatedTransactions);
            Swal.fire({
              title: "Unsubscribed!",
              text: "You have successfully unsubscribed from the service.",
              icon: "success",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            });
          }
        } catch (error) {
          console.error("Error unsubscribing:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error unsubscribing from the service. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
        }
      }
    });
  };

  return (
    <>
      <Header_packages title="My Packages" />
      <Container className="mt-2">
        <Row style={{ marginBottom: "130px", marginTop: "20px" }}>
          {loading ? (
            <p className="text-center">Loading...</p>
          ) : transactions.length === 0 ? (
            <p className="text-center">No active package available.</p>
          ) : (
            transactions.map((transaction, index) =>
              transaction.category ? (
                <Col key={index} md={6} lg={4} className="mb-4">
                  <Card
                    className="custom-card"
                    style={{
                      borderRadius: "20px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <Card.Body>
                      <div className="row">
                        <div className="col-8">
                          <h3>{transaction.activePackage.productName}</h3>
                          <p className="">
                            Sub Mod: {transaction.activePackage.duration}
                          </p>
                          <p className="badge bg-info text-dark">
                            {transaction.category}
                          </p>
                        </div>
                        <div className="col-4">
                          <p className="mb-1">
                            <strong>Price:</strong>{" "}
                            {transaction.activePackage.productPrice}
                          </p>
                          <span
                            className={`badge ${
                              transaction.activePackage.status === "UnSub"
                                ? "bg-danger"
                                : "bg-success"
                            } text-white`}
                          >
                            {transaction.activePackage.status}
                          </span>
                          {transaction.category !== "Call A Doc" &&
                            transaction.activePackage.status !== "UnSub" && (
                              <button
                                type="button"
                                className="mt-3 btn btn-danger px-1"
                                onClick={() => handleUnsubscribe(transaction)}
                              >
                                Unsubscribe
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="row date-bar">
                        <div className="col-6">
                          <strong>Activation:</strong>
                          <div>{transaction.activePackage.activateDate}</div>
                        </div>
                        <div className="col-6">
                          {transaction.category === "Call A Doc" ? (
                            <div>
                              <strong>Expiry Date:</strong>
                              <div>{transaction.activePackage.expiryDate}</div>
                            </div>
                          ) : (
                            <div>
                              <strong>{transaction.activePackage.status === "UnSub"
                                  ? "Expiry Date:"
                                  :'Next Charge:'}</strong>
                              <div>
                                { transaction.activePackage.expiryDate}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <style>
                        {`
                                .date-bar {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  background-color: #e0e0e0;
                                  border-radius: 20px;
                                  padding: 10px 15px;
                                  margin-top: 10px;
                                }
                                .date-bar div {
                                  flex: 1;
                                  text-align: center;
                                }
                              `}
                      </style>
                    </Card.Body>
                  </Card>
                </Col>
              ) : null
            )
          )}
        </Row>
      </Container>
    </>
  );
};

export default MyActivePackages;
