import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
const CenteredModal = ({ show, handleClose, terms }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="p-3"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PackageScreen = ({ items, terms }) => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const [selectedOption, setSelectedOption] = useState(items[0]);
  const [description, setDescription] = useState(
    items.length > 0 ? items[0].description : ""
  );
  const [coverage, setCoverage] = useState(
    items.length > 0 ? items[0].coverage : ""
  );

  const [isChecked, setIsChecked] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleButtonClick = (item) => {
    setSelectedOption(item);
    setDescription(item.description);
    setCoverage(item.coverage);
  };

  const byClickingBuyPackage = useCallback((id, price, type, coverage) => {
    setIsLoading(true);
    const item = sessionStorage.getItem("user");
    const sess = JSON.parse(item);

    const form = new FormData();
    form.append("value", Number(price) * 100);

    const settings = {
      method: "POST",
      body: form,
    };

    fetch(
      `https://easypaiseminiapp.webddocsystems.com/public/api/v1/create-payment`,
      settings
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result.paymentTransactionID) {
          setIsLoading(false);
          if (type === "calladoc") {
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
          }
          // if payment issue then commit this like and then check on browser
          window.mp.createPayment(result.paymentTransactionID);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });

    // if payment issue then comment this line and then check on browser
    // =====>
    window.createPaymentResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      // isLoading(true)
      navigate(`/confirm`, {
        state: {
          type,
          price,
          coverage: coverage,
          pid: id,
          status: result.paymentStatus,
          code: result.resultCode,
          tid: result.paymentTransactionID,
        },
      });
    };

    // =====>
    // if payment issue then uncomment this line and then check on browser

    // navigate(`/confirm`, {
    //   state: {
    //     type,
    //     price,
    //     coverage: coverage,
    //     pid: id,
    //     status: "S",
    //     code: "SUCCESS",
    //     tid: "1123123",
    //   },
    // });
  }, []);

  useEffect(() => {
    const redirectFromPaymentFaild = sessionStorage.getItem(
      "redirectFromPaymentFaild"
    );

    if (redirectFromPaymentFaild === "true") {
      Swal.fire({
        icon: "error",
        title: "Payment Faild",
        text: "Something went wrong please try again.",
        confirmButtonText: "Ok",
      }).then(() => {
        sessionStorage.removeItem("redirectFromPaymentFaild");
      });
    }
  }, []);

  const handlePayNowClick = () => {
    const { id, price, type } = selectedOption;
    byClickingBuyPackage(id, parseInt(price), type, coverage);
  };

  return (
    <div className="container">
      <div style={{ marginBottom: "125px" }}>
        <div className="row mt-2 position-relative">
          <div className="col-12">
            <p className="custome-textfont fw-bold mt-2 mb-1">
              Select price point to subscribe the service
            </p>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="row d-flex justify-content-center">
                <div className="col-md-12 col-12 text-center mb-1 p-2">
                  <div className="packages_list">
                    {items.map((item, index) => (
                      <div className="" key={index}>
                        <Button
                          variant={
                            selectedOption.id === item.id
                              ? "light"
                              : "outline-light"
                          }
                          className="w-100 p-2 py-3 d-flex text-dark align-items-center border-0 border-bottom rounded-0 bg__active"
                          onClick={() => handleButtonClick(item)}
                        >
                          <div className="flex-grow-1 text-start">
                            <div className="d-flex align-items-baseline">
                              <p
                                className="mb-0 fw-bold"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  fontFamily: "Arial, sans-serif",
                                }}
                              >
                                Rs. {Number(item.price).toLocaleString()}
                              </p>
                              <p
                                className="mb-0 text-secondary ms-2"
                                style={{ fontSize: "12px" }}
                              >
                                {item.badge}
                              </p>
                            </div>
                          </div>
                          <div className="flex-shrink-0 me-2">
                            <div
                              className={`package_radio ${
                                selectedOption.id === item.id
                                  ? "package_radio_active"
                                  : ""
                              }`}
                            ></div>
                          </div>
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              {coverage !== "-" && (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <p className="packages_list p-3 fs-15">
                        Coverage:
                        <strong
                          className="float-end"
                          style={{ fontSize: "15px" }}
                        >
                          {" "}
                          Rs. {Number(coverage).toLocaleString()}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <p
                    id="description"
                    className="custome-textfont"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom-center p-2 py-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <small className="text-start">
                <input
                  type="checkbox"
                  checked={isChecked}
                  className="form-check-input terms_checkbox me-2"
                  onChange={(e) => setIsChecked(e.target.checked)}
                />{" "}
                By tapping{" "}
                <strong style={{ color: "#1bb367" }}>"Pay Now"</strong> you are
                accepting our{" "}
                <a
                  href="javascript:void(0);"
                  onClick={handleShow}
                  className="text-decoration-none"
                  style={{ color: "##2240F4" }}
                >
                  Terms & conditions
                </a>
              </small>

              <button
                className="btn btn-success w-100 p-2 rounded-pill border-0"
                onClick={handlePayNowClick}
                disabled={!isChecked}
                style={{ backgroundColor: "#1bb367" }}
              >
                Pay Now
              </button>
              <div className="d-flex justify-content-end align-items-end mt-1">
                <p className="custome-textfont mb-0 text-end me-1 text-secondary">
                  Powered By
                </p>
                <img
                  src="/assets/NewMiniapp/powerd-by-log.png"
                  alt="Logo"
                  className="img-fluid"
                  width={40}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CenteredModal show={show} handleClose={handleClose} terms={terms} />
    </div>
  );
};

export default PackageScreen;
