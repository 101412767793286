class live_stock {
  constructor() {
    this.items = [
      {
        id: 187,
        name: "Daily Plan Basic",
        badge: "Basic",
        coverage: "20,000",
        price: "4",
        bgColorClass: "bg-info",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 20,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
      {
        id: 190,
        name: "Daily Plan Silver",
        badge: "Silver",
        coverage: "40,000",
        price: "8",
        bgColorClass: "bg-warning",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 40,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
      {
        id: 193,
        name: "Daily Plan Gold",
        badge: "Gold",
        coverage: "60,000",
        price: "12",
        bgColorClass: "bg-info",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 60,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
  <p><strong>Key policy terms:</strong></p>
<p>Customer MSISDN required for policy generation</p>
<p>Waiting period of <strong>7 days</strong> is applicable</p>
<p>Only one subscription per phone and per person</p>
<p>Claim should be made within <strong>15 days</strong> of hospitalization</p>
<p><strong>EXCLUSIONS/INCLUSIONS</strong></p>
<ul>
<li>Pre-existing conditions are not covered.</li>
<li>General OPD is covered and need prescriptions and bills to be paid as per limits</li>
<li>Natural or accidentals deaths are not covered</li>
<li>One phone number can have one insurance only</li>
<li>For procedures prescriptions are required and access is <strong> 3000/-</strong></li>
<li>50% cost of vaccinations are covered.</li>
<li>Pregnancies and births are not covered</li>
<li>Payment of claims is subject to exclusions, as outlined below. Live stock Cover shall not cover</li>
<li>Deaths in War, strikes, riots, civil commotion and any natural or man-made perils(including chemical, nuclear, biological, and radiological)</li>
<li>If there are no initial pictures or No pictures at time of claims</li>
<li>If the pictures are not matched</li>
<li>Any other reason for which insurance company believes that the claim should not be paid.</li>
<li>Livestock which is part of fighting or race or any other similar events participants are not covered and</li>
<li>excluded from policy</li>
<li>Pregnancies and births are not covered</li>
</ul>
<p><strong>Eligibility &amp; Enrolment</strong></p>
<p>Applicants are eligible to apply for a Livestock Insurance Plan under the Policy if Applicants meet All the</p>
<p>Criteria set out below:</p>
<p>(1) All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be</p>
<p>Eligible for coverage under the Policy.</p>
<p>(2) All Eligible livestock are healthier and lower than their average last <strong>2 years of age</strong></p>
<p>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</p>
<ol>
<li>Acknowledge that the Applicant has read and understood the terms of the policy.</li>
<li>Confirm that the Applicant meets the eligibility criteria.</li>
<li>The Insurance Benefits payable are subject to the Applicant&rsquo;s confirmations being true and correct;</li>
<li>If the Applicant&rsquo;s confirmation is untrue or incorrect, no Insurance Benefits will be payable, and the End User Price the Subscriber paid will not be refunded.</li>
</ol>
<p><strong>Notice Of Claims</strong></p>
<p>The Company shall be notified of the occurrence of Insured Claim as soon as possible, but not later than <strong>15 (fifteen days)</strong> from the date of demise, which it shall be treated as time-barred and the Company shall not be bound to pay the Claim.</p>
<p>(2) For each Claim reported, the Company shall obtain:</p>
<p>From the Claimant:</p>
<ol>
<li>Veterinary doctor prescription</li>
<li>Medical bills</li>
<li>Owner's CNIC Both Sides</li>
<li>Livestock orignal picture</li>
</ol>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed ten (10) working</p>
<p>days, or as long as the dispute takes to resolve in the legal system.</p>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default live_stock;
