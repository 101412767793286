class women_insurance {
  constructor() {
    this.items = [
//       {
//         id: 194,
//         name: "Daily Plan",
//         badge: "Daily",
//         coverage: "30,000",
//         price: "12",
//         bgColorClass: "bg-info",
//         description: `<p><strong>Swift Women&rsquo;s Health Insurance: </strong>Protect your health with easy and fast online registration.</p>
// <ul>
// <li><strong>Hospitalization Cover:</strong> Up to Rs. 30,000 on pro rata basis</li>
// <li><strong>Special Investigation: </strong>Up to Rs. 10,000</li>
// <li><strong>Accidental Hospitalization Cover: </strong>Additional Rs. 5,000</li>
// <li><strong>Maternity, C-Section, Female-Specific Cancers, Osteoporosis, and Rheumatoid Arthritis:</strong> Up to Rs. 15,000.</li>
// <li><strong>Policy Duration: </strong>1 Month</li>
// <li><strong>Premium Payment: </strong>Daily installments</li>
// <li><strong>Unlimited Tele-Doctor Consultations:</strong> Enjoy unlimited access to online doctor consultations!</li>
// </ul>
// <p><strong>Rapid Claims Processing:</strong> Benefit from our streamlined approach and friendly team for a hassle-free experience.</p>`,
//         type: "insurance",
//       },
      {
        id: 195,
        name: "Monthly Plan",
        badge: "Monthly",
        coverage: "50000",
        price: "199",
        bgColorClass: "bg-warning",
        description: `<p><strong>Women&rsquo;s Health Insurance: </strong>Protect your health with easy and fast online registration.</p>
<ul>
<li><strong>Hospitalization Cover:</strong> Up to Rs. 50,000</li>
<li><strong>Special Investigation:</strong> Up to Rs. 20,000</li>
<li><strong>Accidental Hospitalization Cover:</strong> Additional Rs. 15,000</li>
<li><strong>Maternity, C-Section, Female-Specific Cancers, Osteoporosis, and Rheumatoid Arthritis:</strong> Up to Rs. 30,000</li>
<li><strong>Policy Duration: </strong>1 Month</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Enjoy unlimited access to online doctor consultations!</li>
</ul>
<p><strong>Rapid Claims Processing:</strong> Benefit from our streamlined approach and friendly team for a hassle-free experience.</p>`,
        type: "insurance",
      },
      {
        id: 196,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "200000",
        price: "1999",
        bgColorClass: "bg-info",
        description: `<p><strong>Women&rsquo;s Health Insurance: </strong>Protect your health with easy and fast online registration.</p>
<ul>
<li><strong>Hospitalization Cover:</strong> Up to Rs. 200,000</li>
<li><strong>Special Investigation:</strong> Up to Rs. 30,000</li>
<li><strong>Accidental Hospitalization Cover:</strong> Additional Rs. 20,000</li>
<li><strong>Maternity, C-Section, Female-Specific Cancers, Osteoporosis, and Rheumatoid Arthritis:</strong> Up to Rs. 50,000.</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Enjoy unlimited access to online doctor consultations!</li>
</ul>
<p><strong>Rapid Claims Processing:</strong> Benefit from our streamlined approach and friendly team for a hassle-free experience.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
  <p><strong>Claim &ndash; EXCLUSIONS</strong></p>
<p>Payment of claims is subject to exclusions, as outlined below. Health Insurance Cover shall not cover.</p>
<ul>
<li>Any preexisting conditions.</li>
<li>HIV/Aids related disease are not covered</li>
<li>Intentional and due to quarrel injuries are not covered</li>
<li>OPD of all sorts Any admission less than 24 hours and no overnight stay is considered as OPD, and day care will not be covered</li>
<li>Surgical procedures i.e, cosmetic surgeries, joint replacement, dental procedures are not covered</li>
<li>Regular medications such as Insulin</li>
<li>Any Special Investigations which are not due to the admission pre post are not covered</li>
<li>War, strikes, riots, civil commotion and any natural or manmade perils (including chemical, nuclear, biological, and radiological) that results in the declaration of a state of emergency on a local or national level</li>
<li>Miscarriage purposely isn&rsquo;t covered in insurance.</li>
</ul>
<p><strong>TERMS AND CONDITIONS</strong></p>
<ul>
<li>Claim will be calculated according to normal charges of the hospital around claimant&rsquo;s city</li>
<li>Maximum limit of Surgeon Fee is <b>Rs. 10,000/- </b> in case of surgical procedures and other charges e. anesthesia, operation room charges will be calculated at standard rates</li>
<li>Nursing charges are not payable</li>
<li>Medicines on discharges are not covered</li>
<li>Food charges are excluded</li>
<li>7 Days waiting period applied</li>
</ul>
<p><strong>NOTICE OF CLAIMS</strong></p>
<p>The Company shall be notified of the occurrence of Insured Claim as soon as possible, but not later than <strong>15 (Fifteen days)</strong> from the date of discharge from hospital, which it shall be treated as time barred and the Company shall not be bound to pay the Claim.</p>
<p><strong>For each Claim reported, the Company shall obtain:</strong></p>
<p><strong>From the Claimant:</strong></p>
<ol>
<li>Admission Slip of Hospital</li>
<li>Discharge Slip of Hospital</li>
<li>Doctor Prescription/Note for admission or Medical History</li>
<li>Medical Reports if any</li>
<li>Any Bills</li>
<li>CNIC Both Sides</li>
</ol>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed <b>fifteen (15) working days, </b> or as long as the dispute takes to resolve in the legal system.</p>`;
//     this.terms = `
//   <p><strong>Claim &ndash; EXCLUSIONS</strong></p>
// <p>Payment of claims is subject to exclusions, as outlined below. Health Insurance Cover shall not cover.</p>
// <ul>
// <li>Any preexisting conditions</li>
// <li>HIV/Aids related disease are not covered.</li>
// <li>Intentional and due to quarrel injuries are not covered.</li>
// <li>OPD of all sorts Any admission less than 24 hours and no overnight stay is considered as OPD, and day care will not be covered.</li>
// <li>Surgical procedures, e.g. cosmetic surgeries, joint replacement, dental procedures, are not covered.</li>
// <li>Regular medications such as Insulin</li>
// <li>Any Special Investigations which are not due to the admission or being</li>
// <li>War, strikes, riots, civil commotion and any natural or manmade perils (including chemical, nuclear, biological, and radiological) that results in the declaration of a state of emergency on a local or national level.</li>
// </ul>
// <p><strong>TERMS AND CONDITIONS</strong></p>
// <ul>
// <li>Claim will be calculated according to normal charges of the hospital around claimant&rsquo;s city.</li>
// <li>Maximum limit of Surgeon Fee is Rs. 10,000/- in case of surgical procedures and other charges e. anesthesia, operation room charges will be calculated at standard rates</li>
// <li>Nursing charges are not payable.</li>
// <li>Medicines on discharges are not covered.</li>
// <li>Food charges are excluded.</li>
// <li>Working ladies and working at home ladies cannot claim for income loss compensation.</li>
// <li>7 Days waiting period applied.</li>
// </ul>
// <p><strong>NOTICE OF CLAIMS</strong></p>
// <p>The Company shall be notified of the occurrence of Insured Claim as soon as possible, but not later than 15 (Fifteen days) from the date of discharge from hospital, which it shall be treated as time barred and the Company shall not be bound to pay the Claim.</p>
// <p><strong>For each Claim reported, the Company shall obtain:</strong></p>
// <p><strong>From the Claimant:</strong></p>
// <ol>
// <li>Admission Slip of Hospital</li>
// <li>Discharge Slip of Hospital</li>
// <li>Doctor Prescription/Note for admission or Medical History</li>
// <li>Medical Reports if any</li>
// <li>Any Bills</li>
// <li>CNIC Both Sides</li>
// <li>In the case of income loss compensation, the customer must submit proof of income loss E-salary slip etc.</li>
// </ol>
// <p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed fifteen (15) working days, or as long as the dispute takes to resolve in the legal system.</p>
// <p>Pricing for Products are variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting.</p>
// <p>The insurance policy is set to automatically renew on a monthly, annually basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>
// <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance company.</p>`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default women_insurance;
