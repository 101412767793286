import React from "react";
import { Rings } from "react-loader-spinner";
import { useLoading } from "../contexts/LoadingContext";
import "./loader.css";

const Loader = () => {
  const { isLoading } = useLoading();

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-blur" />
          <Rings
            visible={true}
            height="150"
            width="150"
            color="#4fa94d"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </>
  );
};

export default Loader;
