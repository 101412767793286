class income_protection {
  constructor() {
    this.items = [
//       {
//         id: 165,
//         name: "Daily Plan",
//         badge: "Daily",
//         coverage: "10,000",
//         price: "1",
//         bgColorClass: "bg-info",
//         description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
// <ul>
// <li><strong>In-Patient Department (IPD) Coverage:</strong> Up to 10,000 IPD every month (max Rs.500/day), max 20 days a month</li>
// </ul>
// <p><strong>Effortless Claims:</strong> Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
//         type: "insurance",
//       },
      {
        id: 166,
        name: "Monthly Insurance",
        badge: "Monthly",
        coverage: "20,000",
        price: "150",
        bgColorClass: "bg-warning",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to 20,000 IPD every month (max Rs.2,000/day), max 10 days a month</li>
</ul>
<p><strong>Effortless Claims:</strong>&nbsp;Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
      {
        id: 167,
        name: "6 Months",
        badge: "Bi Annual",
        coverage: "50,000",
        price: "350",
        bgColorClass: "bg-info",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to Rs.50,000 for 6 month (Max Rs.2,000/day), max 25 days in 6 months</li>
</ul>
<p><strong>Effortless Claims:</strong>Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
      {
        id: 168,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "50,000",
        price: "700",
        bgColorClass: "bg-warning",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to Rs.50,000 for 12 month (Max Rs.2,000/day), max 25 days in 1 year</li>
</ul>
<p><strong>Effortless Claims:</strong> Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
     <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
  <p><strong>POLICY TERMS &amp; EXCLUSIONS</strong></p>
<ul>
<li>All plans are auto renew for next <strong>5 years</strong> or till attainment of maximum covered age of the customer whichever comes earlier.</li>
<li>Pre-existing conditions are not covered.</li>
<li>Cosmetic or surgical procedures are not covered.</li>
<li>Regular medicine &amp; SI(Surgical Instruments) doesn&rsquo;t fall under IPD.</li>
<li>MMC(Major Medical Care) &amp; DD(Dread Diseases) cover will apply under IPD admission cover Only.</li>
<li>Hospitalization include any and all diseases/disabilities, admission due to accident.</li>
<li>Cover restricts to IPD; Patient must be admitted at least 24 hours on Bed to avail cover.</li>
<li>Day care surgeries or any admission less than 24 hours including emergencies are not covered.</li>
<li>Maternity admissions or any related surgeries are excluded</li>
<li>Policy can be&nbsp;<strong>reversed within 15 days</strong>&nbsp;after subscription only for 6 month and annual plan.</li>
<li>If the product price changes, the new rates will be apply equally to all policy holders</li>
</ul>
<p><strong>DOCTORS CONSULTANCY &amp; TIMING:</strong></p>
<p>Doctors services are available from Monday &ndash; Saturday 9:00am &ndash; 9:00pm.</p>
<p><strong>CLAIMS PAYMENT:</strong></p>
<p>Claim payment <strong>TAT is 15 working days</strong> after complete documents submission.&nbsp;</p>
<p><strong>REQUIRED DOCUMENTS:</strong></p>
<ol>
<li>CNIC Copy (Both sides)</li>
<li>Treatment details/Doctor&rsquo;s prescription</li>
<li>Admission &amp; discharge slip</li>
<li>Proof of income loss/salary deduction</li>
<li>Lab reports, if any</li>
<li>MSISDN &amp; Easypaisa account</li>
</ol>
<p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance company.</p>
<p>Customer will call on 3737 / 042-111-003-737 / 0344-3333737 intimate about&nbsp;<strong>claim/complaint</strong>&nbsp;or can email all these documents on whsclaims@webdoc.com.pk</p>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default income_protection;
