class crop_insurance {
  constructor() {
    this.items = [
      {
        id: 185,
        name: "Daily Plan Basic",
        badge: "Basic",
        coverage: "20,000",
        price: "5",
        bgColorClass: "bg-info",
        description: `<p><strong>Swift Sign-Up:</strong> Enroll online today to ensure your crops are protected from natural disasters.</p>
<ul>
<li><strong>Coverage: </strong>Up to Rs. 20,000 on a pro-rata basis incase of natural disaster</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Quick &amp; Easy Claims:</strong> Experience smooth and speedy claims processing with our expert and approachable support team.</p>`,
        type: "insurance",
      },
      {
        id: 188,
        name: "Daily Plan Silver",
        badge: "Silver",
        coverage: "40,000",
        price: "10",
        bgColorClass: "bg-warning",
        description: `<p><strong>Swift Sign-Up:</strong> Enroll online today to ensure your crops are protected from natural disasters.</p>
<ul>
<li><strong>Coverage: </strong>Up to Rs. 40,000 on a pro-rata basis incase of natural disaster</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Quick &amp; Easy Claims:</strong> Experience smooth and speedy claims processing with our expert and approachable support team.</p>`,
        type: "insurance",
      },
      {
        id: 191,
        name: "Daily Plan Gold",
        badge: "Gold",
        coverage: "60,000",
        price: "15",
        bgColorClass: "bg-info",
        description: `<p><strong>Swift Sign-Up:</strong> Enroll online today to ensure your crops are protected from natural disasters.</p>
<ul>
<li><strong>Coverage: </strong>Up to Rs. 50,000 on a pro-rata basis incase of natural disaster</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Quick &amp; Easy Claims:</strong> Experience smooth and speedy claims processing with our expert and approachable support team.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
    <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
    <p><strong>Exclusions:</strong></p>
<p>Payment of claims is subject to exclusions, as outlined below.</p>
<p>Crop Insurance Cover shall not cover:</p>
<ul>
<li>Any act which is not natural.</li>
<li><b>7 days</b> consistent payments must be made to make a claim.</li>
<li><b>7 days</b> waiting period applies.</li>
</ul>
<ul>
<li>Customer MSISDN required for policy generation</li>
<li>Only one subscription per phone and per person</li>
<li>Claim should be made within <strong>30 days</strong></li>
<li>coverage as per pro-rata table in case of any natural disaster to your crops, yield in any area of Pakistan</li>
</ul>
<p><strong>Eligibility &amp; Enrolment:</strong></p>
<ul>
<li>Applicants are eligible to apply for a Crop Insurance Plan under the Policy if Applicants meet ALL the criteria set out below:</li>
<li>All Eligible Applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under the Policy.</li>
<li>All Eligible Applicants shall be authorized to apply from the landowners.</li>
<li>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</li>
<li>Acknowledge that the Applicant has read and understood the terms of the Policy.</li>
<li>Confirm that the Applicant meets the eligibility criteria.</li>
<li>the Insurance Benefits payable are subject to the Applicant&rsquo;s confirmations being true and correct; and</li>
<li>If the Applicant&rsquo;s confirmation is untrue or incorrect, no Insurance Benefits will be payable, and the End User Price the Subscriber paid will not be refunded.</li>
<li>Intentional False Statements of The Insured</li>
<li>In the event of any concealment or misrepresentation the Policy shall become null and void with respect to the relevant Insured.</li>
</ul>
<p><strong>Notice Of Claims:</strong></p>
<ul>
<li>The Company shall be notified of the occurrence of Insured Claim as soon as possible, but not later than <strong>30 (Thirty days)</strong> from the date of Natural disaster and his/her loss, which it shall be treated as time‑barred and the Company shall not be bound to pay the Claim.</li>
</ul>
<p><strong>Required Documents for Claims:</strong></p>
<p>For each Claim reported, the Company shall obtain: From the Claimant:</p>
<ol>
<li>Pictures of the affected crop include his picture within the frame. Only real pictures are accepted. Any photo shop will be treated as misrepresentation of claim under fraud.</li>
<li>Where required access to surveyors to land and crop</li>
<li>Owner's CNIC Both Sides</li>
</ol>
<p><strong>Termination Of Individual Insurance</strong></p>
<p>The insurance of an Insured shall automatically terminate at the earliest time below:</p>
<ul>
<li>Upon cancellation or withdrawal of subscription</li>
</ul>
<p><strong>Claims Payments:</strong></p>
<p>Claims Payments will be made after receiving the documentation from the client as per below. claims will be paid in <strong>15 working days</strong>.</p>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed <strong>fifteen (15) working days</strong>, or as long as the dispute takes to resolve in the legal system.</p>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default crop_insurance;
